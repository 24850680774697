import styled from "styled-components";

export const PatentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  //padding: 60px 0px;

  .subtitle {
    text-align: center;
    color: #666;
    margin-bottom: 50px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const SwiperSection = styled.div`
  width: 100%;
  position: relative;

  .swiper-button-next,
  .swiper-button-prev {
    color: #333;
    background: rgba(255, 255, 255, 0.7);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease;

    &:after {
      font-size: 18px;
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 5px;
  }

  .swiper-pagination-bullet-active {
    background: #0066cc;
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      opacity: 1;
    }
  }
`;

export const PartnersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px; /* 모바일에서는 간격 약간 줄임 */
  }
`;

export const PartnerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgBox = styled.div`
  width: 100%;
  height: 140px; /* 높이 증가 (기존 100px) */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transition: all 0.3s ease;
  border-radius: 8px; /* 약간의 둥근 모서리 추가 */

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* 그림자 효과 증가 */
    transform: translateY(-5px); /* 호버 시 더 많이 위로 이동 */
  }

  img.partner-logo {
    max-width: 100%;
    max-height: 110px; /* 이미지 최대 높이 증가 (기존 70px) */
    object-fit: contain;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  p {
    color: #888;
  }
`;
