import React, { useEffect, useState } from "react";
import Link from "next/link";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import http from "../../api/constants/http";
import resources from "../../api/constants/resources";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { useBannerQuery } from "@hook/useBannerQuery";

const SlideArrows = styled.div`
  position: absolute;
`;

const SwiperWrapper = styled.div`
  width: 100%;
  overflow: hidden;

  .P {
    width: 100%;
  }

  .M {
    display: none;
    width: 100%;
  }

  @media (max-width: 1399px) {
    height: auto;
  }
  @media (max-width: 991px) {
    .P {
      display: none;
    }
    .M {
      display: flex;
    }
  }
`;

const SilideEl = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Step = styled.div`
  position: absolute;
  bottom: 25%;
  left: 5%;
  color: #ffaf9d;
  font-size: 55px;
  font-weight: 700;
  @media (max-width: 991px) {
    left: 5%;
    font-size: 45px;
  }
  @media (max-width: 767px) {
    bottom: 20%;
    left: 5%;
    font-size: 2rem;
  }
`;

SwiperCore.use([Navigation, Autoplay]);

const Banner5 = () => {
  const { mainBtmM } = useBannerQuery();
  const [stepRef, inView] = useInView({
    threshold: 0,
  });

  const [steps, setSteps] = useState("");
  const fetchSFNZ = async () => {
    const body = {
      modes: "AllCnt",
    };
    const res = await http(resources.SFNZ).post(body);
    if (res.data?.Code === "0000") {
      setSteps(res.data.Data.count);
    } else {
      console.error(res?.Data?.Msg);
    }
  };

  useEffect(() => {
    fetchSFNZ();
  }, []);

  return (
    <>
      <SwiperWrapper className="container">
        <Swiper
          autoplay={true}
          navigation={{
            prevEl: ".custom_prev_n",
            nextEl: ".custom_next_n",
          }}
          className="custom-class"
          slidesPerView={1}
          spaceBetween={0}
        >
          {mainBtmM &&
            mainBtmM?.length > 0 &&
            mainBtmM.slice(0, 1).map((el, i) => (
              <SwiperSlide key={i}>
                <>
                  <SilideEl
                    data-wow-delay=".2s"
                    ref={stepRef}
                    style={{ borderRadius: "0px" }}
                  >
                    <Link href={el.link_url}>
                      <a target={el.new_win === "Y" ? "_blank" : "_self"}>
                        <img
                          src={el.P_FileUrl}
                          alt={el.img_alt}
                          className="P"
                        />
                        <img
                          src={el.M_FileUrl}
                          alt={el.img_alt}
                          className="M"
                        />
                        <Step>
                          <CountUp
                            start={0}
                            end={steps}
                            duration={0.5}
                            redraw={true}
                            separator=","
                          ></CountUp>
                        </Step>
                      </a>
                    </Link>
                  </SilideEl>
                </>
              </SwiperSlide>
            ))}
        </Swiper>
        {/* <SlideArrows className="slider-arrow slider-arrow-2 carausel-6-columns-arrow">
          <span
            className="slider-btn slider-prev slick-arrow custom_prev_n"
            // style={{ left: "-70px" }}
          >
            <i className="fi-rs-angle-left"></i>
          </span>
          <span
            className="slider-btn slider-next slick-arrow custom_next_n"
            style={{ right: "30px" }}
          >
            <i className="fi-rs-angle-right"></i>
          </span>
        </SlideArrows> */}
      </SwiperWrapper>
    </>
  );
};
export default Banner5;
