import { useEffect, useRef } from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  BannerGridWrapper,
  BannerSection,
} from "@components/layout/home_m_banner/HomeMBanner.styles";
import { useBannerQuery } from "@hook/useBannerQuery";

SwiperCore.use([Navigation, Autoplay, Pagination]);

const HomeMobileBanner = () => {
  const {
    bannerList,
    mobileBtm,
    mainBtmThirdLeft,
    mainBtmThirdCenter,
    mainBtmThirdRight,
  } = useBannerQuery();
  const mobileSwiperRef = useRef();
  useEffect(() => {
    if (bannerList && mobileBtm?.length > 1 && mobileSwiperRef.autoplay) {
      mobileSwiperRef.autoplay.start();
      mobileSwiperRef.autoplay.run();
      mobileSwiperRef.update();
    }
  });

  // 모바일에서 사용할 통합 배너 배열 생성
  const getAllMobileBanners = () => {
    const allBanners = [];

    // main_btm_third_left 배너 추가
    if (mainBtmThirdLeft && mainBtmThirdLeft.length > 0) {
      allBanners.push(...mainBtmThirdLeft);
    }

    // main_btm_third_center 배너 추가
    if (mainBtmThirdCenter && mainBtmThirdCenter.length > 0) {
      allBanners.push(...mainBtmThirdCenter);
    }

    // main_btm_third_right 배너 추가
    if (mainBtmThirdRight && mainBtmThirdRight.length > 0) {
      allBanners.push(...mainBtmThirdRight);
    }

    return allBanners;
  };

  const mobileBanners = getAllMobileBanners();

  return (
    <>
      <BannerGridWrapper className="container">
        {/* 첫 번째 배너 섹션 - PC와 모바일 모두 표시 */}
        <BannerSection>
          {/* PC 배너 */}
          {bannerList &&
            mainBtmThirdLeft?.length > 0 &&
            (mainBtmThirdLeft.length === 1 ? (
              // 이미지가 1개인 경우 그냥 이미지만 표시
              <a
                href={mainBtmThirdLeft[0].link_url}
                target={
                  mainBtmThirdLeft[0].new_win === "Y" ? "_blank" : "_self"
                }
                className="pc_swiper"
              >
                <figure>
                  <img
                    src={mainBtmThirdLeft[0].P_FileUrl}
                    alt={mainBtmThirdLeft[0].img_alt}
                  />
                </figure>
              </a>
            ) : (
              // 2개 이상인 경우 Swiper 사용
              <Swiper
                freeMode={true}
                slidesPerView={1}
                observer={true}
                observeParents={true}
                spaceBetween={25}
                watchSlidesProgress={true}
                className="pc_swiper"
                navigation={true}
                pagination={{ type: "fraction" }}
                loop={true}
                loopAdditionalSlides={1}
              >
                {mainBtmThirdLeft.map((banner, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={banner.link_url}
                      target={banner.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <figure>
                        <img src={banner.P_FileUrl} alt={banner.img_alt} />
                      </figure>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            ))}

          {/* 모바일 배너 - 모든 배너를 하나의 Swiper로 표시 */}
          {mobileBanners.length > 0 && (
            <div className="m_swiper">
              {mobileBanners.length === 1 ? (
                // 통합된 배너가 1개인 경우 그냥 이미지만 표시
                <a
                  href={mobileBanners[0].link_url}
                  target={mobileBanners[0].new_win === "Y" ? "_blank" : "_self"}
                >
                  <figure>
                    <img
                      src={mobileBanners[0].M_FileUrl}
                      alt={mobileBanners[0].img_alt}
                    />
                  </figure>
                </a>
              ) : (
                // 2개 이상인 경우 Swiper 사용
                <Swiper
                  observer={true}
                  observeParents={true}
                  spaceBetween={10}
                  pagination={{ type: "fraction" }}
                  loop={true}
                  loopAdditionalSlides={1}
                  onSwiper={(swiper) => {
                    mobileSwiperRef.current = swiper;
                  }}
                >
                  {mobileBanners.map((banner, i) => (
                    <SwiperSlide key={i}>
                      <a
                        href={banner.link_url}
                        target={banner.new_win === "Y" ? "_blank" : "_self"}
                      >
                        <figure>
                          <img src={banner.M_FileUrl} alt={banner.img_alt} />
                        </figure>
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          )}
        </BannerSection>

        {/* 두 번째 배너 섹션 - PC에서만 표시 */}
        <BannerSection className="pc-only">
          {bannerList &&
            mainBtmThirdCenter?.length > 0 &&
            (mainBtmThirdCenter.length === 1 ? (
              // 이미지가 1개인 경우 그냥 이미지만 표시
              <a
                href={mainBtmThirdCenter[0].link_url}
                target={
                  mainBtmThirdCenter[0].new_win === "Y" ? "_blank" : "_self"
                }
              >
                <figure>
                  <img
                    src={mainBtmThirdCenter[0].P_FileUrl}
                    alt={mainBtmThirdCenter[0].img_alt}
                  />
                </figure>
              </a>
            ) : (
              // 2개 이상인 경우 Swiper 사용
              <Swiper
                freeMode={true}
                slidesPerView={1}
                observer={true}
                observeParents={true}
                spaceBetween={25}
                watchSlidesProgress={true}
                navigation={true}
                pagination={{ type: "fraction" }}
                loop={true}
                loopAdditionalSlides={1}
              >
                {mainBtmThirdCenter.map((banner, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={banner.link_url}
                      target={banner.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <figure>
                        <img src={banner.P_FileUrl} alt={banner.img_alt} />
                      </figure>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            ))}
        </BannerSection>

        {/* 세 번째 배너 섹션 - PC에서만 표시 */}
        <BannerSection className="pc-only">
          {bannerList &&
            mainBtmThirdRight?.length > 0 &&
            (mainBtmThirdRight.length === 1 ? (
              // 이미지가 1개인 경우 그냥 이미지만 표시
              <a
                href={mainBtmThirdRight[0].link_url}
                target={
                  mainBtmThirdRight[0].new_win === "Y" ? "_blank" : "_self"
                }
              >
                <figure>
                  <img
                    src={mainBtmThirdRight[0].P_FileUrl}
                    alt={mainBtmThirdRight[0].img_alt}
                  />
                </figure>
              </a>
            ) : (
              // 2개 이상인 경우 Swiper 사용
              <Swiper
                freeMode={true}
                slidesPerView={1}
                observer={true}
                observeParents={true}
                spaceBetween={25}
                watchSlidesProgress={true}
                navigation={true}
                pagination={{ type: "fraction" }}
                loop={true}
                loopAdditionalSlides={1}
              >
                {mainBtmThirdRight.map((banner, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={banner.link_url}
                      target={banner.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <figure>
                        <img src={banner.P_FileUrl} alt={banner.img_alt} />
                      </figure>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            ))}
        </BannerSection>
      </BannerGridWrapper>
    </>
  );
};
export default HomeMobileBanner;
