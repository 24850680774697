import http from "@api/constants/http";
import resources from "@api/constants/resources";

// 카테고리텝 상품목록
export const BestProductsListSet = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);
  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
