import styled from "styled-components";

export const HeaderTitle = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & li {
    font-size: 0.9rem !important;
    cursor: pointer;
  }

  /* 전체보기 버튼 */
  & li.hover-up {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 10px;

    /* 모바일에서 요소 정렬 */
    & h4 {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    & li.hover-up {
      order: 3; /* 마지막 순서로 설정 */
      font-size: 0.8rem !important;
    }
  }
`;

export const HeaderCategory = styled.div`
  display: flex;
  flex-grow: 1; /* 가능한 공간을 차지하도록 설정 */
  justify-content: center; /* 중앙 정렬 */
  flex-wrap: wrap;
  padding-right: 7%;

  @media (max-width: 767px) {
    order: 2; /* 중간 순서로 설정 */
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-right: 0;

    & li {
      margin: 3px;
    }

    & .nav-link {
      padding: 5px 10px;
      font-size: 0.85rem !important;
    }
  }

  @media (max-width: 480px) {
    & .nav-link {
      padding: 3px 8px;
      font-size: 0.8rem !important;
    }
  }
`;
