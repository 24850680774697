import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination]);
import * as T from "./HomeTopBanner.styles";
import Image from "next/image";
import { useEffect, useState } from "react";
import { fetchBoards } from "../../../api/boards";
import { Spinner } from "react-bootstrap";
import { shuffleArray } from "../../../common/helper";
import { useBannerQuery } from "@hook/useBannerQuery";

const HomeTopBanner = () => {
  const { bannerList, mainTopEvent, mainTopCenterEvent } = useBannerQuery();
  const [noticeList, setNoticeList] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [bannersLoading, setBannersLoading] = useState(true);
  const [shuffledBanners, setShuffledBanners] = useState([]);
  const fetchNoticeList = async () => {
    setFetchLoading(true);
    const body = {
      modes: "List",
      boardName: "notice",
    };
    const res = await fetchBoards(body);
    if (res.code === "0000" && res?.data?.data?.length > 0) {
      setNoticeList([...res.data.data.slice(0, 5)]);
    }
    setFetchLoading(false);
  };

  useEffect(() => {
    fetchNoticeList();
  }, []);

  useEffect(() => {
    if (
      bannerList &&
      mainTopEvent?.length > 0 &&
      mainTopCenterEvent &&
      shuffledBanners.length === 0
    ) {
      setShuffledBanners(
        shuffleArray([...mainTopEvent, ...mainTopCenterEvent])
      );
      setBannersLoading(false);
    }
  }, [bannerList]);

  return (
    <>
      <T.HomeTopBannerWrapper className="container">
        <T.Section>
          {/* pc swiper */}
          {bannerList &&
            mainTopEvent?.length > 0 &&
            (mainTopEvent.length === 1 ? (
              // 이미지가 1개인 경우 그냥 이미지만 표시
              <a
                href={mainTopEvent[0].link_url}
                target={mainTopEvent[0].new_win === "Y" ? "_blank" : "_self"}
              >
                <figure>
                  <Image
                    src={mainTopEvent[0].P_FileUrl}
                    width={362}
                    height={198}
                    sizes="100vw"
                    priority
                    alt={mainTopEvent[0].img_alt}
                  />
                </figure>
              </a>
            ) : (
              // 2개 이상인 경우 Swiper 사용
              <Swiper
                freeMode={true}
                slidesPerView={1}
                observer={true}
                observeParents={true}
                spaceBetween={25}
                watchSlidesProgress={true}
                className="pc_swiper"
                navigation={true}
                pagination={{ type: "fraction" }}
                loop={true}
                loopAdditionalSlides={1}
              >
                {mainTopEvent.map((banner, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={banner.link_url}
                      target={banner.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <figure>
                        <Image
                          src={banner.P_FileUrl}
                          width={362}
                          height={198}
                          sizes="100vw"
                          priority
                          alt={banner.img_alt}
                        />
                      </figure>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            ))}

          {/* mobile Swiper */}
          {bannersLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          ) : (
            shuffledBanners?.length > 0 &&
            (shuffledBanners.length === 1 ? (
              // 이미지가 1개인 경우 그냥 이미지만 표시
              <a
                href={shuffledBanners[0].link_url}
                target={shuffledBanners[0].new_win === "Y" ? "_blank" : "_self"}
              >
                <figure>
                  <Image
                    src={shuffledBanners[0].M_FileUrl}
                    width={326}
                    height={105}
                    sizes="100vw"
                    priority
                    alt={shuffledBanners[0].img_alt}
                  />
                </figure>
              </a>
            ) : (
              // 2개 이상인 경우 Swiper 사용
              <Swiper
                observer={true}
                observeParents={true}
                spaceBetween={10}
                className="m_swiper"
                pagination={{ type: "fraction" }}
                loop={true}
                loopAdditionalSlides={1}
              >
                {shuffledBanners.map((banner, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={banner.link_url}
                      target={banner.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <figure>
                        <img src={banner.M_FileUrl} alt={banner.img_alt} />
                      </figure>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            ))
          )}
        </T.Section>
        <T.Section className="pc-only">
          {/* pc swiper - 모바일에서는 보이지 않음 */}
          {bannerList &&
            mainTopCenterEvent?.length > 0 &&
            (mainTopCenterEvent.length === 1 ? (
              // 이미지가 1개인 경우 그냥 이미지만 표시
              <a
                href={mainTopCenterEvent[0].link_url}
                target={
                  mainTopCenterEvent[0].new_win === "Y" ? "_blank" : "_self"
                }
              >
                <figure>
                  <Image
                    src={mainTopCenterEvent[0].P_FileUrl}
                    width={362}
                    height={198}
                    sizes="100vw"
                    priority
                    alt={mainTopCenterEvent[0].img_alt}
                  />
                </figure>
              </a>
            ) : (
              // 2개 이상인 경우 Swiper 사용
              <Swiper
                freeMode={true}
                slidesPerView={1}
                observer={true}
                observeParents={true}
                spaceBetween={25}
                watchSlidesProgress={true}
                className="pc_swiper"
                navigation={true}
                pagination={{ type: "fraction" }}
                loop={true}
                loopAdditionalSlides={1}
              >
                {mainTopCenterEvent.map((banner, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={banner.link_url}
                      target={banner.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <figure>
                        <Image
                          src={banner.P_FileUrl}
                          width={362}
                          height={198}
                          sizes="100vw"
                          priority
                          alt={banner.img_alt}
                        />
                      </figure>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            ))}
        </T.Section>

        <T.HomeTopNoticeWrapper>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6>Notice</h6>
            <a href="/csboard/notice/">more</a>
          </div>
          {fetchLoading ? (
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Spinner />
            </div>
          ) : (
            <ul>
              {noticeList?.length > 0 &&
                noticeList.map((v, i) => (
                  <li key={i}>
                    <a href={`/csboard/notice/${v.id}/`}>
                      <span className="title">{v.title}</span>
                      <span className="date">{v.wdate}</span>
                    </a>
                  </li>
                ))}
            </ul>
          )}
        </T.HomeTopNoticeWrapper>
      </T.HomeTopBannerWrapper>
    </>
  );
};
export default HomeTopBanner;
