import React, { useEffect, useState, useRef } from "react";
import BestProductTab from "@components/elements/BestProductTab";
import { HeaderCategory, HeaderTitle } from "./bestproduct.styles";
import { useGetBestProductsList } from "@hook/best-products/useBestProductsQuery";
import { useRouter } from "next/router";

const categoryTranslation = {
  daily: "일간",
  weekly: "주간",
  monthly: "월간",
};

const BestProduct = () => {
  const router = useRouter();
  const [active, setActive] = useState("daily");
  const [isHovering, setIsHovering] = useState(false);
  const productTabRef = useRef(null);

  const {
    data: catProducts = {},
    isPending: fetchIsLoading,
    error,
  } = useGetBestProductsList({ limit: "4" });

  // 데이터가 로드되었을 때 첫 번째 카테고리를 active로 설정
  useEffect(() => {
    if (catProducts && Object.keys(catProducts).length > 0 && !active) {
      setActive(Object.keys(catProducts)[0]);
    }
  }, [catProducts, active]);

  const handleCategoryChange = async (type) => {
    setActive(type);
  };

  // 제품 탭 영역에 마우스 호버 이벤트 추가
  useEffect(() => {
    const productTabElement = productTabRef.current;

    if (!productTabElement) return;

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    productTabElement.addEventListener("mouseenter", handleMouseEnter);
    productTabElement.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      productTabElement.removeEventListener("mouseenter", handleMouseEnter);
      productTabElement.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const keys = Object.keys(catProducts);
    if (keys.length === 0) return;

    // 호버 중일 때는 자동 전환을 중지
    if (isHovering) return;

    const updateActiveCategory = () => {
      setActive((prev) => {
        const currentIndex = keys.findIndex((key) => key === prev);
        const nextIndex = (currentIndex + 1) % keys.length;
        return keys[nextIndex];
      });
    };

    const intervalId = setInterval(updateActiveCategory, 5000);
    return () => clearInterval(intervalId);
  }, [catProducts, isHovering]);

  return (
    <>
      <div className="section-title style-2 wow animate__animated animate__fadeIn">
        <HeaderTitle className="nav nav-tabs links" id="myTab" role="tablist">
          <h4>베스트 상품</h4>
          <HeaderCategory>
            {Object.keys(catProducts).map((category, index) => (
              <li className="nav-item" role="presentation" key={index}>
                <a
                  className={
                    active === category ? "nav-link active" : "nav-link"
                  }
                  onClick={() => handleCategoryChange(category)}
                  role="button"
                >
                  {categoryTranslation[category] || category}
                </a>
              </li>
            ))}
          </HeaderCategory>

          <li
            className="hover-up"
            onClick={() => router.push("/products/best")}
          >
            {"전체보기"}
          </li>
        </HeaderTitle>
      </div>

      <div className="tab-content wow fadeIn animated">
        <div className="tab-pane fade show active">
          <div className="product-grid-4" ref={productTabRef}>
            <BestProductTab products={catProducts[active]} />
          </div>
        </div>
      </div>
    </>
  );
};
export default BestProduct;
