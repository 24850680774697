import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "@common/queryKeys";
import { BestProductsListSet } from "@api/bestProducts";

// 카테고리 상세내용 불러오기
export const useGetBestProductsList = ({ limit }) => {
  const queryKey =
    limit === "4"
      ? [QUERY_KEYS.PRODUCTS.BESTPRODUCTS.DETAIL]
      : [QUERY_KEYS.PRODUCTS.BESTPRODUCTS.INDEX, limit];
  return useQuery({
    queryKey,
    queryFn: async () => {
      try {
        const res = await BestProductsListSet({ modes: "BestListSet", limit }); // API 호출

        if (res.Code === "0000") {
          return res.Data.Data || {}; // 정상 데이터 반환
        } else {
          console.error(res?.message); // 에러 처리
        }
      } catch (error) {
        console.error(error); // 네트워크 에러 등
      }
    },
    staleTime: 1000 * 60 * 5, // 5분 동안 데이터 유지
    retry: 1, // 요청 실패 시 한 번만 재시도
  });
};
