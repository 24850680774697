import styled from "styled-components";
export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center; // 컨테이너 자체를 중앙으로
    width: 100%;
  }

  h4 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;

    @media (max-width: 767px) {
      margin-bottom: 30px;
      text-align: center;
      width: 100%;
    }
  }

  .nav-tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;

    @media (max-width: 767px) {
      width: 100%;
      justify-content: center; // flex 아이템들을 중앙으로 정렬
      padding-bottom: 5px;
      flex-direction: row; // 명시적으로 가로 방향 설정
    }
  }

  .nav-item {
    margin: 0;

    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
    }
  }

  .nav-link {
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 15px;
    color: #777;
    background-color: #f8f8f8;
    border: none;
    transition: all 0.3s ease;
    cursor: pointer;

    &.active {
      color: #fff;
    }

    &:hover:not(.active) {
      background-color: #eee;
    }

    @media (max-width: 767px) {
      padding: 6px 12px;
      font-size: 14px;
      white-space: nowrap;
      text-align: center;
    }
  }
`;
