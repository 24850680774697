import CategoryTab from "../components/ecommerce/categoryTab";
import Banner5 from "./../components/elements/Banner5";
import Layout from "./../components/layout/Layout";
import CategorySlider from "./../components/sliders/Category";
import Intro1 from "./../components/sliders/Intro1";
import Instagram from "../components/subscribe/subscribe";
import styled from "styled-components";
import Newsletter from "../components/newsletter/newsletter";
import Popup from "../components/popup/popup";
import SideBanner from "../components/layout/sideBanner";
import HomeMobileBanner from "../components/layout/home_m_banner/Home_m_banner";
import { connect } from "react-redux";
import PatentSlider from "../components/sliders/patent/patent";
import HomeTopBanner from "../components/layout/home_top_banner/HomeTopBanner";
import ClientCompany from "@components/client-company/ClientCompany";
import BestProduct from "@components/best-product/BestProduct";

const SwiperSection = styled.section`
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

function Home({ siteConfig }) {
  return (
    <>
      <Popup />
      <SideBanner />
      <Layout noBreadcrumb="d-none" siteConfig={siteConfig}>
        {/* 메인 상단 배너 */}
        <SwiperSection className="position-relative overflow-hidden">
          <Intro1 />
        </SwiperSection>
        {/* 상단 슬라이드 배너 */}
        <HomeTopBanner />

        {/* 상품 카테고리 */}
        <section className="popular-categories section-padding mb-15 position-relative">
          <div className="container wow animate__fadeIn animate__animated position-relative">
            <CategorySlider />
          </div>
        </section>
        {/* 베스트 상품 */}
        <section className="product-tabs section-padding position-relative overflow-hidden mb-0 mb-lg-2">
          <div className="container overflow-hidden">
            <BestProduct />
          </div>
        </section>
        {/* 추천상품 */}
        <section className="product-tabs section-padding position-relative overflow-hidden mb-0 mb-lg-2">
          <div className="container overflow-hidden">
            <div className="col-lg-12">
              <CategoryTab />
            </div>
          </div>
        </section>
        {/* 메인 이벤트 띠 배너 */}
        <section className="mb-15">
          <div className="container wow animate__fadeIn animate__animated">
            <HomeMobileBanner />
          </div>
        </section>
        {/* 스텝포넷제로 발자국 */}
        <section className="banners mb-2 mb-lg-5 section-padding">
          <div className="container">
            <div className="row">
              <Banner5 />
            </div>
          </div>
        </section>
        {/*고객사*/}
        <section className="mb-15">
          <div className="container wow animate__fadeIn animate__animated">
            <ClientCompany />
          </div>
        </section>
        {/* 인기컨텐츠 */}
        <section className="banners mb-2 mb-lg-5 section-padding">
          <Instagram />
        </section>
        {/* 구독 */}
        <section className="banners mb-2 mb-lg-5 section-padding">
          <Newsletter />
        </section>
        {/* 특허 및 인증 */}
        <section className="mb-2 mb-lg-5 section-padding">
          <PatentSlider />
        </section>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => ({
  banners: state.banners,
});

export default connect(mapStateToProps, null)(Home);
