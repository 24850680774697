import React, { useEffect, useRef, useState } from "react";
import Cat1Tab from "../elements/FeaturedTab";
import { fetchProductsMain } from "../../api/products";
import {
  ProductGridContainer,
  SectionTitleContainer,
  TabContainer,
} from "@style/categoryTabeStyle";

function CategoryTab() {
  const [active, setActive] = useState("");
  const [catProducts, setCatProducts] = useState({});
  const [isHovering, setIsHovering] = useState(false);
  const productTabRef = useRef(null);

  const fetchProducts = async () => {
    const body = { modes: "Main" };
    const data = await fetchProductsMain(body);
    setCatProducts(data?.data);
    setActive(Object.keys(data?.data)[0]);
  };

  const catType = async (type) => {
    setActive(type);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // 상품 탭 영역에 마우스 호버 이벤트 추가
  useEffect(() => {
    const productTabElement = productTabRef.current;

    if (!productTabElement) return;

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    productTabElement.addEventListener("mouseenter", handleMouseEnter);
    productTabElement.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      productTabElement.removeEventListener("mouseenter", handleMouseEnter);
      productTabElement.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const keys = Object.keys(catProducts);
    if (keys.length === 0) return;

    // 호버 중일 때는 자동 전환을 중지
    if (isHovering) return;

    const updateActiveCategory = () => {
      setActive((prev) => {
        const currentIndex = keys.findIndex((key) => key === prev);
        const nextIndex = (currentIndex + 1) % keys.length;
        return keys[nextIndex];
      });
    };

    const intervalId = setInterval(updateActiveCategory, 5000);
    return () => clearInterval(intervalId);
  }, [catProducts, isHovering]);

  return (
    <>
      <TabContainer className="wow animate__animated animate__fadeIn">
        <h4>추천상품</h4>

        <ul className="nav nav-tabs links" id="myTab" role="tablist">
          {catProducts &&
            Object.keys(catProducts).length > 0 &&
            Object.keys(catProducts).map((cat, i) => (
              <li className="nav-item" role="presentation" key={i}>
                <a
                  className={active === cat ? "nav-link active" : "nav-link"}
                  onClick={() => catType(cat)}
                >
                  {cat}
                </a>
              </li>
            ))}
        </ul>
      </TabContainer>

      <div className="tab-content wow fadeIn animated">
        <div className={"tab-pane fade show active"}>
          <div className="product-grid-4 row" ref={productTabRef}>
            <Cat1Tab products={catProducts[active]} />
          </div>
        </div>
      </div>
    </>
  );
}
export default CategoryTab;
