import { useRouter } from "next/router";

SwiperCore.use([Navigation, Autoplay, Pagination]);
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import * as H from "./patent.styles";
import { LicenseAPI } from "../../../api/license";
import { useEffect, useState } from "react";
import { HeaderTitle } from "./patent.styles";

const PatentSlider = () => {
  const router = useRouter();
  const [licenseList, setLicenseList] = useState([]);
  const openImg = (img) => {
    window.open(img);
  };

  const fetchLicenseList = async () => {
    const body = {
      modes: "ListSet",
    };
    const res = await LicenseAPI(body);
    if (res?.Code === "0000") {
      setLicenseList(res?.Data?.Data?.rows);
    } else {
      console.error(res.Data?.Msg || "리스트 불러오기 에러");
    }
  };

  useEffect(() => {
    fetchLicenseList();
  }, []);

  return (
    <>
      <H.PatentWrapper className="">
        <H.FooterTitle className="container">
          <h4>특허 및 인증</h4>
          <H.SlideArrows>
            <li
              className="hover-up"
              onClick={() => router.push("/about?scrollTo=technology")}
              style={{ cursor: "pointer" }}
            >
              {"전체보기"}
            </li>
            {/*<span className="slider-btn custom_prev_p1">*/}
            {/*  <i className="fi-rs-arrow-small-left"></i>*/}
            {/*</span>*/}
            {/*<span className="slider-btn custom_next_p1">*/}
            {/*  <i className="fi-rs-arrow-small-right"></i>*/}
            {/*</span>*/}
          </H.SlideArrows>
        </H.FooterTitle>
        <div className="container ">
          <Swiper
            id="patentSlider"
            autoplay={true}
            loop={true}
            spaceBetween={20}
            navigation={{
              prevEl: ".custom_prev_p1",
              nextEl: ".custom_next_p1",
            }}
            className="custom-class"
            slidesPerView={2}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1440: {
                slidesPerView: 6,
              },
            }}
            initialSlide={0}
          >
            {licenseList.map((el, i) => (
              <SwiperSlide key={i}>
                <H.SliderBox onClick={() => openImg(el.url)}>
                  <img src={el.url} alt="" />
                  <span>{el.name}</span>
                </H.SliderBox>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </H.PatentWrapper>
    </>
  );
};
export default PatentSlider;
