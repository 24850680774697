import styled from "styled-components";
import SingleProduct from "../ecommerce/singleProduct/SingleProduct";

const SingleProductTap = styled.div`
  width: 25%; /* 기본적으로 4개 정렬 (25% x 4 = 100%) */
  padding: 0 10px;
  position: relative;

  @media (max-width: 991px) {
    width: 33.333%; /* 태블릿에서는 3개 정렬 */
  }

  @media (max-width: 767px) {
    width: 50%; /* 작은 태블릿과 모바일에서는 2개 정렬 */
  }

  @media (max-width: 349px) {
    width: 100%; /* 매우 작은 화면에서는 1개 정렬 */
  }
`;

const RankBadge = styled.div`
  position: absolute;
  top: 0px;
  left: 24px;
  z-index: 100;
  width: 52px;
  height: 60px;
  background-image: url("https://static-resource-smartstore.pstatic.net/smartstore/p/static/20250219164238/img/sprite/svg/spSs_theme_svg.svg");
  background-size: 300px 222px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* 순위에 따른 배경 위치 설정 */
  &.rank-1 {
    background-position: -64px -72px;
  }

  &.rank-2 {
    background-position: -64px -72px;
  }

  &.rank-3 {
    background-position: -64px -72px;
  }

  &.rank-4 {
    background-position: -4px -72px;
  }

  /* 순위 숫자 */
  & span.rank-number {
    position: relative;
    color: white;
    font-size: 20px;
    font-weight: bold;
    margin-top: -10px; /* 위치 조정 */
    z-index: 2;
  }

  /* BEST 텍스트 */
  & span.rank-text {
    position: relative;
    color: white;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: -5px; /* 위치 조정 */
    z-index: 2;
  }

  /* 모바일에서 크기 조정 */
  @media (max-width: 767px) {
    width: 45px;
    height: 60px;

    & span.rank-number {
      font-size: 18px;
    }

    & span.rank-text {
      font-size: 9px;
    }
  }
`;
const BestProductTab = ({ products }) => {
  return (
    <div className="row">
      {products &&
        products?.map((product, i) => (
          <SingleProductTap key={i}>
            <RankBadge className={`rank-${i + 1}`} rank={i + 1}>
              <span className="rank-number">{i + 1}</span>
              <span className="rank-text">BEST</span>
            </RankBadge>
            <SingleProduct product={product} />
          </SingleProductTap>
        ))}
    </div>
  );
};

export default BestProductTab;
