import { useCallback, useEffect, useState } from "react";
import { PartnersListSet } from "@api/partners";
import * as P from "./client.styles";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Autoplay } from "swiper";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { useRouter } from "next/router";

SwiperCore.use([Navigation, Pagination]);

const ClientCompany = () => {
  const router = useRouter();
  const [paginatedPartners, setPaginatedPartners] = useState([]);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 1200
  );

  // 화면 크기에 따라 itemsPerPage 결정
  const getItemsPerPage = useCallback(() => {
    if (windowWidth <= 768) {
      return 6; // 모바일 (768px 이하): 6개
    } else if (windowWidth <= 992) {
      return 12; // 태블릿 (992px 이하): 12개
    } else {
      return 12; // 데스크톱: 12개
    }
  }, [windowWidth]);

  // 파트너 리스트를 itemsPerPage 크기로 그룹화
  const paginatePartners = useCallback(
    (partners) => {
      const totalPartners = partners.length;
      const paginatedData = [];
      const itemsPerPage = getItemsPerPage();

      for (let i = 0; i < totalPartners; i += itemsPerPage) {
        paginatedData.push(partners.slice(i, i + itemsPerPage));
      }

      setPaginatedPartners(paginatedData);
    },
    [getItemsPerPage]
  );

  const fetchPartners = async () => {
    const body = {
      modes: "ListSet",
    };
    try {
      const res = await PartnersListSet(body);
      if (res.Code === "0000") {
        const partners = res.Data?.rows || [];
        paginatePartners(partners);
      } else {
        console.error(res?.Data?.Msg);
      }
    } catch (error) {
      console.error("Failed to fetch partners:", error);
    }
  };

  //재조회
  useEffect(() => {
    fetchPartners();
  }, []);

  // 화면 크기 변경 감지
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 화면 크기가 변경될 때 파트너 데이터 재구성
  useEffect(() => {
    // fetchPartners가 이미 호출된 후에만 실행
    if (paginatedPartners.length > 0) {
      // 모든 파트너를 단일 배열로 평탄화
      const allPartners = paginatedPartners.flat();
      // 새로운 화면 크기에 맞게 다시 페이지네이션
      paginatePartners(allPartners);
    }
  }, [windowWidth, paginatePartners]);

  return (
    <P.PatentWrapper>
      <P.HeaderContainer className="container">
        <h4>고객사</h4>

        <li
          className="hover-up"
          onClick={() => router.push("/partners")}
          style={{ cursor: "pointer" }}
        >
          {"더보기"}
        </li>
      </P.HeaderContainer>

      {paginatedPartners.length > 0 ? (
        <P.SwiperSection className="position-relative overflow-hidden">
          <Swiper
            slidesPerView={1}
            autoplay={true}
            loop={true}
            pagination={false}
            navigation
            initialSlide={0}
          >
            {paginatedPartners.map((page, pageIndex) => (
              <SwiperSlide key={pageIndex}>
                <P.PartnersGrid>
                  {page.map((partner, index) => (
                    <P.PartnerItem key={`${pageIndex}-${index}`}>
                      <P.ImgBox>
                        <img
                          className="partner-logo"
                          src={partner.img_url}
                          alt={partner.img_alt || `파트너사 로고 ${index + 1}`}
                        />
                      </P.ImgBox>
                    </P.PartnerItem>
                  ))}
                </P.PartnersGrid>
              </SwiperSlide>
            ))}
          </Swiper>
        </P.SwiperSection>
      ) : (
        <P.LoadingWrapper>
          <p>파트너사 정보를 불러오는 중입니다...</p>
        </P.LoadingWrapper>
      )}
    </P.PatentWrapper>
  );
};
export default ClientCompany;
