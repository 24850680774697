import styled from "styled-components";

export const BannerGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  height: 100%;
  margin-bottom: 30px;

  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .swiper-button-prev {
    color: #3bb77e;
    left: 0px;
    ::after {
      font-size: 30px;
    }
  }

  .swiper-button-next {
    color: #3bb77e;
    right: 0px;
    ::after {
      font-size: 30px;
    }
  }

  .swiper-pagination-fraction {
    background-color: #000;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 20px;
    left: auto;
    right: 15px;
    color: #fff;
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
  }
`;

export const BannerSection = styled.section`
  overflow: hidden;
  width: 100%;
  position: relative;

  .pc_swiper {
    display: block;
  }

  .m_swiper {
    display: none;
    overflow: visible;
    margin: 0 auto;
    position: relative;
  }

  figure {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    padding: 0px;

    .pc_swiper {
      display: none;
    }

    .m_swiper {
      display: block;
    }
  }

  &.pc-only {
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;
